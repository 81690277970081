<template>
  <div class="onboard">
    <section>
      <div class="centered-box">
        <h3 class="txt-white is-size-4" @click="getUser()">
          Just a second! We are setting up your account...
        </h3>
      </div>
    </section>
  </div>
</template>

<script>
import {
  IsEmptyOrNull
} from '@/utils/utils'

export default {
  name: 'ConfirmAccount',
  data () {
    return {}
  },
  methods: {
    getUser () {
      const self = this
      this.$store.dispatch('user/getUser', {
        cb: (user) => {
          if (user.roles[0] === 'clinic') {
            self.$router.push({ path: '/clinic/personal-informations' })
          } else {
            self.$router.push({ path: '/laboratory/info-laboratory' })
          }
        },
        cbError: (error) => {
          console.log(error)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.error
            }
          })
          self.$router.push({ path: '/' })
        }
      })
    }
  },
  created () {
    const self = this
    this.$store.dispatch('user/confirmAccount', {
      code: this.$route.params.invitationCode,
      cb: (response) => {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'success',
            message: 'Bienvenido!'
          }
        })
        self.getUser()
      },
      cbError: (error) => {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error.error
          }
        })
        if (IsEmptyOrNull(self.$route.params.type)) {
          self.$router.push({ path: '/' })
        } else {
          if (self.$route.params.type === 'laboratory') {
            self.$router.push({ path: '/laboratory/login' })
          } else {
            self.$router.push({ path: '/clinic/login' })
          }
        }
      }
    })
  }
}
</script>

<style scoped lang="scss">
.onboard{
  position: fixed;
  height: calc(100% - 70px);
  width: 100%;
  top: 70px;
  left: 0px;
  section{
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/images/2-service-pages-hero.jpg");
    overflow: hidden;
    .centered-box{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .onboard{
    top: 49px;
    height: calc(100% - 49px);
    section{
      .centered-box{
        width: 100%;
        max-width: 600px;
        padding: 0 14px;
        .button{
          width: 100%;
        }
      }
    }
  }
}
</style>
